<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <!-- Export Modal -->
    <b-modal
      class="p-2"
      id="export-modal"
      hide-header-close
      hide-header
      hide-footer
    >
      <b-row align-h="end">
        <button
          class="close_modal text-light border-none p-0 mx-2 mb-1"
          @click="$bvModal.hide('export-modal')"
        >
          <b-icon-x scale="1.5" />
        </button>
      </b-row>
      <p class="text-center pb-3 btn-auto">{{ $t("asset.choose_ex") }}</p>
      <b-row align-h="center">
        <!-- <button class="px-2 email-me" @click="onExport(true)">
          {{ $t("btn.send_mail") }}
        </button> -->

        <button class="px-2 download-file" @click="onExport(false)">
          {{ $t("btn.dl_file") }}
        </button>
      </b-row>
    </b-modal>

    <!-- Write off info modal -->
    <b-modal
      class="p-2"
      id="writeoff-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
    >
      <div class="p-3">
        <p>{{ $t("depreciation.pause_date") }}</p>
        <date-picker
          class="inline-block h-full"
          locale="en"
          v-model="pauseDate"
          :max-date="new Date()"
        >
          <template v-slot="{ inputValue, togglePopover }">
            <div
              class="w-100 input-arrow me-3 my-1 d-flex align-items-center"
              @click="
                () => {
                  assetIsDeactivate || togglePopover();
                }
              "
            >
              {{ inputValue | formatDate }}
            </div>
          </template>
        </date-picker>
        <p>{{ $t("depreciation.writeoff_date") }}</p>
        <form @submit.prevent="getWriteoffSummary">
          <date-picker
            class="inline-block h-full"
            locale="en"
            v-model="writeoffDate"
            :max-date="new Date()"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <div
                class="w-100 input-arrow me-3 my-1 d-flex align-items-center"
                @click="togglePopover"
              >
                {{ inputValue | formatDate }}
              </div>
            </template>
          </date-picker>
          <p>{{ $t("depreciation.wirteoff_price") }}</p>
          <vue-numeric
            separator=","
            decimal-separator="."
            :precision="2"
            class="w-100 form-control form-border"
            :placeholder="$t('filter.lowest_price')"
            v-model="writeoffPrice"
          ></vue-numeric>
          <p>{{ $t("depreciation.writeoff_note") }}</p>
          <b-textarea
            class="w-100"
            rows="5"
            v-model="writeoffNote"
            required
          ></b-textarea>
          <div class="row justify-content-center gapx-2">
            <button
              class="cancel-button"
              type="button"
              @click="$bvModal.hide('writeoff-modal')"
            >
              {{ $t("btn.canc") }}
            </button>
            <button class="confirm-button" type="submit">
              {{ $t("btn.conf") }}
            </button>
          </div>
        </form>
      </div>
    </b-modal>

    <!-- Write off Summary Table Modal -->
    <b-modal
      class="p-2"
      id="summary-modal"
      hide-header-close
      hide-header
      hide-footer
      centered
      size="xl"
    >
      <div class="p-3">
        <div class="mb-4 text-center">
          <h3 class="gradient-text">
            {{ $t("depreciation.writeoff_summary") }}
          </h3>
          <h6>
            {{
              $t("depreciation.writeoff_total", {
                total: writeoffSummaryData.length,
              })
            }}
          </h6>
        </div>
        <SummaryTable :assetData="writeoffSummaryData"></SummaryTable>
        <div class="row justify-content-center gapx-2">
          <button class="cancel-button" @click="$bvModal.hide('summary-modal')">
            {{ $t("btn.canc") }}
          </button>
          <button class="confirm-button" @click="actionToAsset">
            {{ $t("btn.conf") }}
          </button>
        </div>
      </div>
    </b-modal>

    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div
          class="menu d-flex flex-wrap align-items-center justify-content-between my-3"
        >
          <div class="d-flex align-items-center flex-wrap">
            <button
              class="btn-outline danger-btn me-3 my-1"
              :disabled="!selectedAssetLength"
              @click="$bvModal.show('writeoff-modal')"
            >
              <p>{{ $t("depreciation.writeoff") }}</p>
            </button>
            <customWriteoff
              @show-summary="getCustomWriteoffSummary"
            ></customWriteoff>
            <div class="position-relative">
              <div class="pinging"></div>
              <div class="ping"></div>
            </div>
          </div>
          <div class="search-box d-flex" style="border-radius: 10px;">
            <img src="@/assets/search-white.svg" width="20px" />
            <input
              type="text"
              v-debounce:700ms="searchByText"
              :placeholder="$t('home.search')"
              v-model="searchText"
            />
          </div>
        </div>
        <MasterTable
          @selected-assets="setSelectedAsset"
          :assetData="getAllAssets"
          :otherColumn="getAllAssetOtherColumn"
          :page="currentPage"
        />
        <div class="d-flex justify-content-center mt-3">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            aria-controls="my-table"
            @change="loadAssetData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import Swal from "sweetalert2";
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import MasterTable from "../../components/Table/Depreciation/MasterDepreciationTable.vue";
import SummaryTable from "../../components/Table/Depreciation/WriteoffSummaryTable.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

import customWriteoff from "../../components/CustomComponent/SubAsset/customWriteoff.vue";

import { mapGetters } from "vuex";
import baseUrl from "../../util/backend";
export default {
  name: "WriteoffAsset",
  components: {
    MasterTable,
    SummaryTable,
    DatePicker,
    Loading,
    customWriteoff,
  },
  data() {
    return {
      isLoading: true,
      company_id: null,
      pauseDate: moment().format(),
      writeoffDate: moment().format(),
      writeoffPrice: 0,
      writeoffNote: "",
      selectedAsset: [],
      searchText: "",
      filterOptions: {},
      action: "deactivate",
      currentPage: 1,
      totalRows: 1,
      perPage: 100,
      writeoffSummaryData: [],
      // ------------------- START Custom 3 import writeoff ------------------------
      isCustomWriteoff: false,
      // ------------------- END Custom 3 import writeoff ------------------------
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      getAllAssets: "getAllAssetsStore",
      getAllAssetOtherColumn: "getAllAssetOtherColumn",
      assets_count: "assets_count",
      companyName: "companyName",
    }),
    selectedAssetLength() {
      return this.selectedAsset.length > 0;
    },
    assetIsDeactivate() {
      if (this.selectedAsset.length === 1) {
        return this.selectedAsset[0].depreciation_status.status === "Deactivate"
          ? true
          : false;
      } else {
        return false;
      }
    },
  },

  methods: {
    async onExport(isMail) {
      this.isLoading = true;
      try {
        const res = await this.$store.dispatch("ExportAsset", {
          depreciation_status: ["active", "deactivate", "expire"],
          is_requesting: "null",
          is_email: isMail,
          company_id: this.company_id,
          filterOptions: this.filterOptions,
          filename: `${this.$t("asset.as_regdoc")}_${
            this.companyName
          }_${moment().format("DD-MM-YYYY_HH-mm")}.xlsx`,
          type: "docRegisAsset",
          selected_assets: [],
        });
        this.isLoading = false;
      } catch (err) {
        console.log(err);
        this.isLoading = false;
      }
    },
    async getWriteoffSummary() {
      try {
        this.isLoading = true;
        const assetMapID = this.selectedAsset.map((asset) => asset.id_asset);
        const res = await axios.get(
          `${baseUrl()}asset/getwriteoffsummary/${this.company_id}`,
          {
            ...authHeader(),
            params: {
              id_asset_list: assetMapID,
              deactivate_at: moment(this.pauseDate).toISOString(),
              write_off_date: moment(this.writeoffDate).toISOString(),
              write_off_amount: this.writeoffPrice,
              note_write_off: this.writeoffNote || "-",
            },
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            },
          }
        );
        console.log(res.data);
        this.writeoffSummaryData = res.data.data;
        this.$bvModal.show("summary-modal");
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
    // ------------------- START Custom 3 import writeoff ------------------------
    async getCustomWriteoffSummary(data) {
      if (data.length > 0) {
        this.writeoffSummaryData = data;
        const woData = data[0];
        this.selectedAsset = data;
        this.pauseDate = woData.deactivate_at;
        this.writeoffDate = woData.write_off_date;
        this.writeoffPrice = +woData.write_off_amount || 0;
        this.writeoffNote = woData.note_write_off;
        this.$bvModal.show("summary-modal");
      }
    },
    // ------------------- END Custom 3 import writeoff ------------------------
    async actionToAsset() {
      this.isLoading = true;
      const assetMapID = this.selectedAsset.map((asset) => asset.id_asset);
      try {
        await axios.put(
          `${baseUrl()}asset/writeOffAsset/${this.company_id}`,
          {
            id_asset_list: assetMapID,
            deactivate_at: moment(this.pauseDate)
              .startOf("d")
              .toISOString(),
            write_off_date: moment(this.writeoffDate)
              .startOf("d")
              .toISOString(),
            write_off_amount: this.writeoffPrice,
            note_write_off: this.writeoffNote,
          },
          authHeader()
        );
        this.writeoffNote = "";
        this.writeoffPrice = 0;
        this.pauseDate = moment().format();
        this.writeoffDate = moment().format();
        this.$bvModal.hide("summary-modal");
        this.$bvModal.hide("writeoff-modal");
        Swal.fire({
          icon: "success",
          text: this.$t("depreciation.success_request"),
          showConfirmButton: false,
          showCancelButton: false,
          timer: 3000,
        });
        this.loadAssetData();
      } catch (err) {
        if (err.response) {
          if (
            err.response.data.message ===
            "Some assets not found / can't calculate depreciation"
          ) {
            alert(this.$t("depreciation.depreciation_error_dialog"));
          } else if (
            err.response.data.message ===
              "The write off date/pause date shouldn't be before than start date" ||
            err.response.data.message ===
              "The pause date shouldn't be after expire date"
          ) {
            alert(this.$t("depreciation.writeoff_error_dialog"));
          } else {
            alert(err.response.data.message);
          }
        } else {
          alert(err.message);
        }
        this.$bvModal.hide("summary-modal");
      }
      this.isLoading = false;
    },

    setSelectedAsset(value) {
      this.selectedAsset = value;
    },
    async searchByText() {
      this.filterOptions.text = this.searchText;
      this.currentPage = 1;
      this.loadAssetData();
    },
    async loadAssetData() {
      this.selectedAsset = [];
      this.isLoading = true;
      this.company_id = await this.$store.dispatch("getCompanyIdCookie");
      await this.$store.dispatch("filterAllAsset_pagination", {
        depreciation_status: ["active", "deactivate", "expire"],
        page_name: "write_off",
        is_requesting: "null",
        filterOptions: this.filterOptions,
        saveToStore: false,
        countingStatus: this.countingStatus,
        page: this.currentPage,
        company_id: this.company_id,
        limit: 100,
        calculate_depreciation: true,
      });
      this.totalRows = this.assets_count;
      this.isLoading = false;
    },
  },
  mounted() {
    this.loadAssetData();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

.btn-outline {
  p {
    margin: 0;
  }
  padding: 10px 15px;
  background-color: #fff;
  border: 2px solid;
  border-radius: 8px;
  &.primary-btn {
    color: #007afe;
    border-color: #007afe;
  }
  &.danger-btn {
    color: #f74949;
    border-color: #f74949;
  }

  &:disabled {
    filter: grayscale(1);
    opacity: 0.5;
    cursor: default !important;
  }
}

.form-control {
  min-width: 200px;
  width: 200px;
}

.search-box {
  input {
    width: 200px;
    background-color: transparent !important;
    border-radius: 10px;
  }
  img {
    margin: 0 10px;
  }
  background-color: #e0e0e0 !important;
}

.input-arrow {
  user-select: none;
  cursor: pointer;
  min-width: 200px;
  width: 200px;
  height: 45px;
  background-color: #f4f4f4;
  padding: 0.375rem 0.75rem;
  color: #212529;
  border-radius: 0.25rem;
  background-image: url("../../assets/down-arrow-black.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 97% 50% !important;
  padding-right: 40px !important;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}

.gradient-text {
  background: -webkit-linear-gradient(#007afe 0%, #013f81 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*
  Animation
*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
